<template>
  <v-container>
    <v-row>
      <v-col>
        <div class="d-flex mt-4">
          <v-btn
            x-small
            class="white--text mt-3 ml-2"
            color="warning"
            elevation="0"
            @click="
              () =>
                this.$router.push({
                  path: '/cost-items',
                })
            "
          >
            <v-icon small> mdi-arrow-left </v-icon>
          </v-btn>
          <h2 class="ml-4">{{ costItemName }}</h2>
          <v-spacer></v-spacer>
          <div class="p-4 mr-4">
            <v-select
              v-model="selectedCampaignId"
              open-on-clear
              :items="selectableCampaigns"
              clearable
              item-text="displayName"
              item-value="id"
              label="Campaña"
              dense
              outlined
              @change="reactToChange"
            ></v-select>
          </div>
        </div>
        <div class="d-flex-col mt-2">
          <v-chip class="mr-2" v-if="regionalVariants" color="warning">
            Variantes Regionales
          </v-chip>
          <v-chip v-if="activityVariants" color="warning">
            Variantes Actividad
          </v-chip>
        </div>
      </v-col>
    </v-row>
    <v-row
      v-if="!loading"
      style="max-height: 400px; overflow-y: scroll"
      id="scrollComponent"
    >
      <v-col align="center" justify="center">
        <div
          v-for="(item, indx) in filteredListedItems"
          :key="item.listedDirectSliceCostItemId"
          :ref="'item-' + indx"
        >
          <ListedCostItemElement
            v-if="!isLoading(item)"
            class="mb-2"
            :costItem="item"
            @itemDelete="reactToChange"
          />
          <div v-else>
            <v-progress-circular
              :width="5"
              size="40"
              color="primary"
              indeterminate
              v-intersect="onIntersect"
            ></v-progress-circular>
          </div>
        </div>
        <div class="text-center" v-if="filteredListedItems.length < 1">
          <span
            class="text-overline grey--text text-subtitle-2"
            style="font-size: 18px !important"
          >
            Sin precios para mostrar
          </span>
        </div>
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col>
        <div class="text-center">
          <span
            class="text-overline grey--text text-subtitle-2"
            style="font-size: 18px !important"
          >
            Cargando precios...
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-divider class="mt-2 mb-4"></v-divider>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <NewListedCostItem
          :selectedCampaignId="selectedCampaignId"
          :directSliceCostItemId="costItemId"
          :costItem="listCostItem"
          @postSuccess="reactToChange"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
// import Table from "../components/Table";
import { mapGetters } from "vuex";
import { getSliceProductsListByItemId } from "../managrx/services";
import ListedCostItemElement from "../components/CostItemManagement/ListedCostItemElement.vue";
import NewListedCostItem from "../components/CostItemManagement/NewListedCostItem.vue";
import useSelectables from "../mixins/useSelectables";
import { getRequest } from "../managrx/serverCall";

export default {
  mixins: [useSelectables],
  components: { ListedCostItemElement, NewListedCostItem },
  computed: {
    ...mapGetters(["costItemFamilies", "directSliceCostItems", "campaigns"]),
    costItemName() {
      return this.listCostItem?.name ?? "Lista de precios";
    },
    regionalVariants() {
      return this.listCostItem?.hasRegionVariants;
    },
    activityVariants() {
      return this.listCostItem?.hasActivityVariants;
    },
    filteredListedItems() {
      return this.listItems?.length
        ? this.addLoading
          ? [
              ...this.listItems,
              { listedDirectSliceCostItemId: Date.now(), isLoading: true },
            ]
          : this.listItems
        : [];
    },
    addLoading() {
      return this.listItems?.length && this.listItems?.length == this.skip;
    },
    listItems() {
      return this.listObject;
    },
  },

  async mounted() {
    this.loading = true;
    this.costItemId = parseInt(this.$route.params.id);
    this.$store.dispatch("setPageLoadingStatus", true);

    let [costItemResponse, listObjectResponse] = await Promise.all([
      getRequest(`/direct_slice_cost_item/${this.costItemId}`),
      getSliceProductsListByItemId(
        this.costItemId,
        this.selectedCampaignId,
        this.skip,
        this.limit
      ),
      this.$store.dispatch("getAbstractCampaigns"),
      this.$store.dispatch("getCampaigns"),
      this.$store.dispatch("getActivities"),
    ]);

    if (!costItemResponse.success) {
      this.$store.dispatch("setNewNotification", costItemResponse);
    } else {
      this.listCostItem = costItemResponse.data;
    }
    if (!listObjectResponse.success) {
      this.$store.dispatch("setNewNotification", listObjectResponse);
    } else {
      this.listObject = listObjectResponse.data;
    }
    this.skip = this.skip + this.limit;

    this.$store.dispatch("setPageLoadingStatus", false);
    this.loading = false;
  },
  methods: {
    scrollToTopList() {
      const elem = document.getElementById("scrollComponent");
      elem.scrollTop = 0;
    },
    isLoading(item) {
      console.log(Boolean(item?.isLoading));
      return Boolean(item?.isLoading);
    },
    async onIntersect(isIntersecting, entries, observer) {
      this.isIntersecting = observer;
      if (this.isIntersecting) {
        await this.getNextBatch();
      }
    },
    async getNextBatch() {

      this.limit = 10;

      let listObjectResponse = await getSliceProductsListByItemId(
        this.costItemId,
        this.selectedCampaignId,
        this.skip,
        this.limit
      );
      if (!listObjectResponse.success) {
        this.$store.dispatch("setNewNotification", this.listObject);
      } else {
        this.listObject.push(...listObjectResponse.data);
        this.skip = this.skip + this.limit;
      }
    },
    async reactToChange() {
      this.$store.dispatch("setPageLoadingStatus", true);
      this.scrollToTopList();
      this.skip = 0;
      this.limit = 10;

      let listObjectResponse = await getSliceProductsListByItemId(
        this.costItemId,
        this.selectedCampaignId,
        this.skip,
        this.limit
      );
      if (!listObjectResponse.success) {
        this.$store.dispatch("setNewNotification", this.listObject);
      } else {
        this.listObject = listObjectResponse.data;
        this.skip = this.skip + this.limit;
      }
      this.$store.dispatch("setPageLoadingStatus", false);
    },
    navToCostItems() {
      this.$router.push("/cost-items");
    },
  },
  data() {
    return {
      limit: 10,
      listCostItem: {},
      isIntersecting: false,
      skip: 0,
      loading: false,
      selectedCampaignId: null,
      costItemId: null,
      listObject: [],
    };
  },
};
</script>
